import { useMemo } from 'react'

import { DEVICE_WIDTH } from '@/utils/constants'

interface IArgs {
  lastPageIndex: number
  currPageIndex: number
  paginationNumbersToDisplay?: number
}

export const usePaginationGenerator = ({
  lastPageIndex,
  currPageIndex,
  paginationNumbersToDisplay = window.innerWidth <= DEVICE_WIDTH.MOBILE ? 1 : 3, // TODO: verify that it works with SSR (otherwise follow: https://stackoverflow.com/questions/59494037/how-to-detect-the-device-on-react-ssr-app-with-next-js)
}: IArgs): any[] => {
  return useMemo(
    () =>
      computePaginationNumbers({
        lastPageIndex,
        currPageIndex,
        paginationNumbersToDisplay,
      }),
    [currPageIndex, lastPageIndex]
  )
}

const computePaginationNumbers = ({
  lastPageIndex,
  currPageIndex,
  paginationNumbersToDisplay,
}): any[] => {
  if (lastPageIndex <= 1) {
    return [1]
  }

  const [startPaginationMainRange, endPaginationMainRange] = computePaginationMainRange(
    currPageIndex,
    lastPageIndex,
    paginationNumbersToDisplay
  )

  const paginationMainRangeNumbers = [
    ...Array(endPaginationMainRange + 1 - startPaginationMainRange),
  ].map((_, index) => startPaginationMainRange + index)

  return [
    ...(startPaginationMainRange > 1
      ? addPaginationBeforeRangeNumbers(startPaginationMainRange)
      : []),
    ...paginationMainRangeNumbers,
    ...(endPaginationMainRange !== lastPageIndex
      ? addPaginationAfterRangeNumbers(endPaginationMainRange, lastPageIndex)
      : []),
  ]
}

const computePaginationMainRange = (
  currPageIndex: number,
  lastPageIndex: number,
  paginationNumbersToDisplay: number
): [number, number] => {
  paginationNumbersToDisplay =
    lastPageIndex <= paginationNumbersToDisplay
      ? lastPageIndex
      : paginationNumbersToDisplay

  const maxPagesBeforeCurrPage = Math.floor(paginationNumbersToDisplay / 2)

  // Begin?
  if (currPageIndex <= maxPagesBeforeCurrPage) {
    return [1, paginationNumbersToDisplay]
  }

  const maxPagesAfterCurrPage = Math.ceil(paginationNumbersToDisplay / 2) - 1

  // End?
  if (currPageIndex + maxPagesAfterCurrPage >= lastPageIndex) {
    return [lastPageIndex - paginationNumbersToDisplay + 1, lastPageIndex]
  }

  // Middle
  return [currPageIndex - maxPagesBeforeCurrPage, currPageIndex + maxPagesAfterCurrPage]
}

const addPaginationBeforeRangeNumbers = (firstPaginationNumber: number): any[] => {
  if (firstPaginationNumber <= 2) {
    return [1]
  }

  const ellipsis = firstPaginationNumber > 3 ? '...' : 2
  return [1, ellipsis]
}

const addPaginationAfterRangeNumbers = (
  lastPaginationNumber: number,
  lastPageIndex: number
): any[] => {
  if (lastPaginationNumber === lastPageIndex - 1) {
    return [lastPageIndex]
  }

  const ellipsis = lastPaginationNumber < lastPageIndex - 2 ? '...' : lastPageIndex - 1
  return [ellipsis, lastPageIndex]
}
