import React from 'react'
import { Option as IMultiSelectOption } from 'react-multi-select-component'

import { ItemRenderer as SelectorItemRenderer } from '@/components/_shared/filters/Selector'
import { SelectInput } from '@/components/_shared/form/SelectInput'
import { IPropertyKey, IPropertyValue } from '@/types/main'

export interface IPropertySelectorProps<T = string | number> {
  propKey: IPropertyKey
  selectedValues: T[]
  onSelectFn: (selectedValues: T[]) => void
}

export const PropertySelector: React.FC<IPropertySelectorProps> = ({
  propKey,
  selectedValues,
  onSelectFn,
}) => (
  <SelectInput
    selectedValues={selectedValues}
    labelledBy={propKey.slug}
    valueRenderer={() => propKey.title}
    options={buildPropValueListOptions(propKey.propValues)}
    onChange={(opts: IMultiSelectOption[]) => {
      onSelectFn(opts.map((option) => option.value))
    }}
    ItemRenderer={SelectorItemRenderer}
  />
)

export interface IMultiSelectPropValueOption<T = IPropertyValue>
  extends IMultiSelectOption {
  propValue: T
  childDepth?: number
}

export const buildPropValueListOptions = (
  propValues: IPropertyValue[]
): IMultiSelectPropValueOption[] =>
  propValues?.map((propValue) => ({
    label: propValue.title,
    value: propValue._id,
    propValue,
  }))

export const buildPropValueListWithDepthOptions = <T extends IPropertyValue>(
  propValues: T[]
): IMultiSelectPropValueOption<T>[] => {
  const buildOption = <T extends IPropertyValue>(
    propValue: T,
    childDepth = 0
  ): IMultiSelectPropValueOption<T> => ({
    label: propValue.title,
    value: propValue._id,
    propValue,
    childDepth,
  })

  return propValues?.reduce(
    (acc, propValue) => [
      ...acc,
      buildOption(propValue),
      ...propValue.children.flatMap((propValueChild1) => [
        buildOption(propValueChild1, 1),
        ...propValueChild1.children.map((propValueChild2) =>
          buildOption(propValueChild2, 2)
        ),
      ]),
    ],
    []
  )
}
