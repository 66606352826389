import React from 'react'
import { UrlObject } from 'url'

import { NavLink, NavSpan } from './style'

interface LinkProps {
  paginationNumber: number | string
  currPageIndex: number
  href?: UrlObject
}

export const PaginatorNavLink: React.FC<LinkProps> = ({
  paginationNumber,
  currPageIndex,
  href,
}) => {
  if (paginationNumber === '...') {
    return <NavSpan>...</NavSpan>
  }

  if (paginationNumber === currPageIndex) {
    return <NavSpan $current={true}>{paginationNumber}</NavSpan>
  }

  return <NavLink href={href}>{paginationNumber}</NavLink>
}
