import React, { createContext, useContext, useState } from 'react'

import { IPaginationInfo } from '@/services/api-graphql'

export interface IPaginationContext {
  paginationInfo: IPaginationInfo
  setPaginationInfo: React.Dispatch<React.SetStateAction<IPaginationInfo>>
}

export const PaginationContext = createContext<IPaginationContext | undefined>(undefined)

export const PaginationContextProvider: React.FC<React.PropsWithChildren> = ({
  children,
}) => {
  const [paginationInfo, setPaginationInfo] = useState<IPaginationInfo>({
    totalCount: null,
    lastPage: null,
  })

  return (
    <PaginationContext.Provider value={{ paginationInfo, setPaginationInfo }}>
      {children}
    </PaginationContext.Provider>
  )
}

export const usePagination = (): IPaginationContext => {
  const context = useContext(PaginationContext)

  if (context === undefined) {
    throw new Error('usePagination must be called inside a subtree of PaginationProvider')
  }

  return context
}
