import React, { useEffect, useMemo, useState } from 'react'
import styled, { css } from 'styled-components'

import {
  buildPropValueListWithDepthOptions,
  IMultiSelectPropValueOption,
  IPropertySelectorProps,
} from '@/components/_shared/filters/PropertySelector'
import {
  IItemRendererProps,
  SelectorCheckBoxInput,
  SelectorCheckedIcon,
  SelectorItemWrapper,
  SelectorLabel,
} from '@/components/_shared/filters/Selector'
import { SelectInput } from '@/components/_shared/form/SelectInput'
import { IPropertyValueDiscipline } from '@/types/main'
import { DEVICE_WIDTH } from '@/utils/constants'

export const PropertyDisciplineSelector: React.FC<IPropertySelectorProps<number>> = ({
  propKey,
  selectedValues,
  onSelectFn,
}) => {
  const options = useMemo(
    () => buildPropValueListWithDepthOptions(propKey.propValues),
    []
  )
  const [effectiveValues, setEffectiveValues] = useState<number[]>([])
  const [clickedOptionAction, setClickedOptionAction] = useState<{
    option: IMultiSelectPropValueOption<IPropertyValueDiscipline>
    action: 'select' | 'unselect'
  }>(null)

  useEffect(() => {
    if (clickedOptionAction === null) {
      return
    }

    const { option: clickedOption, action: clickedAction } = clickedOptionAction
    const clickedPropValue = clickedOption.propValue
    const isPractice = !!clickedPropValue.childrenIdentifiers.length

    // Selection?
    if (clickedAction === 'select') {
      // - Practice? Rm its possible disciplines already set and add the practice
      if (isPractice) {
        onSelectFn([
          ...selectedValues.filter(
            (v) => !clickedPropValue.childrenIdentifiers.includes(v)
          ),
          clickedPropValue._id,
        ])
        return
      }

      // - Discipline?
      const isPracticeFullySelected = clickedPropValue.parent.childrenIdentifiers.every(
        (i) => [...selectedValues, clickedPropValue._id].includes(i)
      )
      // -- With practice fully selected? Rm all its disciplines already set and add only the practice
      if (isPracticeFullySelected) {
        onSelectFn([
          ...selectedValues.filter(
            (v) => !clickedPropValue.parent.childrenIdentifiers.includes(v)
          ),
          clickedPropValue.parent._id,
        ])
        return
      }

      // -- Without practice fully selected? Simple add
      onSelectFn([...selectedValues, clickedPropValue._id])
      return
    }

    // Unselection?
    // - Practice? Simple remove
    if (isPractice) {
      onSelectFn(selectedValues.filter((v) => v !== clickedPropValue._id))
      return
    }

    // - Discipline?
    const isPracticeSelected = selectedValues.includes(clickedPropValue.parent._id)
    // -- With its practice already selected? Rm the practice, and add its disciplines except the clicked one
    if (isPracticeSelected) {
      onSelectFn([
        ...selectedValues.filter((v) => v !== clickedPropValue.parent._id),
        ...clickedPropValue.parent.childrenIdentifiers.filter(
          (v) => v !== clickedPropValue._id
        ),
      ])
      return
    }

    // -- Without its practice selected? Simple remove
    onSelectFn(selectedValues.filter((v) => v !== clickedPropValue._id))
  }, [clickedOptionAction])

  useEffect(() => {
    setEffectiveValues(buildEffectiveValues(selectedValues, options))
  }, [selectedValues])

  return (
    <SelectInput
      selectedValues={effectiveValues}
      labelledBy={propKey.slug}
      valueRenderer={() => propKey.title}
      options={options}
      // eslint-disable-next-line @typescript-eslint/no-empty-function
      onChange={() => {}}
      ItemRenderer={(
        props: IItemRendererProps<IMultiSelectPropValueOption<IPropertyValueDiscipline>>
      ) => (
        <ItemPropValueDisciplineRenderer
          {...props}
          onClick={() => {
            setClickedOptionAction({
              option: props.option,
              action: props.checked ? 'unselect' : 'select',
            })
            props.onClick()
          }}
        />
      )}
    />
  )
}

const buildEffectiveValues = (
  selectedValues: number[],
  options: IMultiSelectPropValueOption<IPropertyValueDiscipline>[]
) =>
  selectedValues.reduce((acc, value) => {
    const propValue = options.find((pv) => pv.value === value).propValue
    const isPractice = !!propValue.childrenIdentifiers.length

    // Practice? Add all its disciplines ids
    return [...acc, propValue._id, ...(isPractice ? propValue.childrenIdentifiers : [])]
  }, [])

const ItemPropValueDisciplineRenderer: React.FC<
  IItemRendererProps<IMultiSelectPropValueOption<IPropertyValueDiscipline>>
> = ({ checked, option, disabled, onClick }) => {
  if (option.childDepth === 0) {
    return <PillarWrapper>{option.label}</PillarWrapper>
  }

  return (
    <ItemDisciplineWrapper
      key={option.value}
      className={`item-renderer ${disabled && 'disabled'}`}
      $disabled={disabled}
      $childDepth={option.childDepth}
    >
      <SelectorCheckBoxInput
        checked={checked}
        onClick={onClick}
        disabled={disabled}
        readOnly
      />
      {checked && <SelectorCheckedIcon />}
      <SelectorLabel $checked={checked}>{option.label}</SelectorLabel>
    </ItemDisciplineWrapper>
  )
}

const ItemDisciplineWrapper = styled(SelectorItemWrapper)<{
  $disabled: boolean
  $childDepth?: number
}>`
  ${({ $childDepth = 0 }) => getDisciplineChildDepthStyle($childDepth)}
`
const getDisciplineChildDepthStyle = (childDepth: number) => {
  if (!childDepth) return

  if (childDepth === 1) {
    return css`
      ${({ theme }) => theme.typo.bold}

      background-color: ${({ theme }) => theme.color.beigeLight};
      padding: ${({ theme }) => `${theme.spacing.xs} ${theme.spacing.s}`};

      @media screen and (min-width: ${DEVICE_WIDTH.TABLET}px) {
        margin: 0 -${({ theme }) => theme.spacing.s};
      }
    `
  }

  return css`
    padding-left: ${({ theme }) => theme.spacing.m};

    @media screen and (min-width: ${DEVICE_WIDTH.TABLET}px) {
      padding-left: ${({ theme }) => theme.spacing.xs};
    }
  `
}
const PillarWrapper = styled.div`
  ${({ theme }) => theme.typo.bold}

  background-color: ${({ theme }) => theme.color.beige};
  cursor: auto;
  padding: ${({ theme }) => `${theme.spacing.xxs} ${theme.spacing.xs}`};

  @media screen and (min-width: ${DEVICE_WIDTH.TABLET}px) {
    margin: 0 -${({ theme }) => theme.spacing.s};
  }
`
