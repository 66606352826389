import styled, { css } from 'styled-components'

import { NextLink } from '@/components/_shared/NextLink'

export const NavLink = styled(NextLink)`
  ${({ theme }) => theme.typo.bold}

  align-items: center;
  color: ${({ theme }) => theme.color.terracota};
  display: flex;
  height: ${({ theme }) => theme.spacing.l};
  justify-content: center;
  max-width: calc((100vw - ${({ theme }) => theme.spacing.l}) / 7);
  transition: background-color 300ms;
  width: ${({ theme }) => theme.spacing.l};

  &:hover {
    background-color: ${({ theme }) => theme.color.terracotaDark};
    color: ${({ theme }) => theme.color.white};
    text-decoration: none;

    & > svg {
      stroke: ${({ theme }) => theme.color.white};
    }
  }
`
export const NavSpan = styled.span<{ $current?: boolean }>`
  ${({ theme }) => theme.typo.bold}

  align-items: center;
  color: ${({ theme }) => theme.color.terracota};
  display: flex;
  height: ${({ theme }) => theme.spacing.l};
  justify-content: center;
  max-width: calc((100vw - ${({ theme }) => theme.spacing.l}) / 7);
  width: ${({ theme }) => theme.spacing.l};
  ${({ $current, theme }) =>
    $current &&
    css`
      background-color: ${theme.color.terracota};
      color: ${theme.color.white};
    `}
`
