import { useRouter } from 'next/router'
import { ParsedUrlQueryInput } from 'querystring'
import React from 'react'
import styled from 'styled-components'
import { UrlObject } from 'url'

import { LeftChevronIcon } from '@/components/_svg/icons/ChevronIcon'
import { usePaginationGenerator } from '@/hooks/use-paginationGenerator'

import { PaginatorNavLink } from './PaginatorNavLink'
import { NavLink, NavSpan } from './style'

interface IProps {
  lastPageIndex: number
  currPageIndex: number
  pageIndexParam?: string
}

export const Paginator: React.FC<IProps> = ({
  lastPageIndex,
  currPageIndex,
  pageIndexParam = 'pageIndex',
}) => {
  const router = useRouter()
  const paginationNumbers = usePaginationGenerator({ lastPageIndex, currPageIndex })
  const baseHref = {
    pathname: router.pathname,
    query: router.query,
  }

  return (
    <Nav aria-label="pagination">
      <NavUl>
        <NavLi>
          {currPageIndex > 1 ? (
            <NavLink href={buildPageRoute(baseHref, currPageIndex - 1, pageIndexParam)}>
              <StyledLeftChevronIcon />
            </NavLink>
          ) : (
            <NavSpan>
              <StyledLeftChevronIcon />
            </NavSpan>
          )}
        </NavLi>

        {paginationNumbers.map((paginationNumber, index) => (
          <NavLi key={index}>
            <PaginatorNavLink
              paginationNumber={paginationNumber}
              currPageIndex={currPageIndex}
              href={
                paginationNumber !== '...' &&
                buildPageRoute(baseHref, paginationNumber, pageIndexParam)
              }
            />
          </NavLi>
        ))}

        <NavLi>
          {currPageIndex < lastPageIndex ? (
            <NavLink
              href={
                currPageIndex < lastPageIndex
                  ? buildPageRoute(baseHref, currPageIndex + 1, pageIndexParam)
                  : ''
              }
            >
              <StyledRightChevronIcon />
            </NavLink>
          ) : (
            <NavSpan>
              <StyledRightChevronIcon />
            </NavSpan>
          )}
        </NavLi>
      </NavUl>
    </Nav>
  )
}

export const buildPageRoute = (
  baseHref: UrlObject,
  pageNumber: number,
  pageIndexParam = 'pageIndex'
): UrlObject => ({
  ...baseHref,
  query: {
    ...(baseHref.query as ParsedUrlQueryInput),
    [pageIndexParam]: pageNumber,
  },
})

const Nav = styled.nav`
  margin-top: ${({ theme }) => theme.spacing.l};
`
const NavUl = styled.ul`
  align-items: center;
  display: flex;
  justify-content: center;
  margin: 0;
  padding: 0;
`
const NavLi = styled.li`
  border: 1px solid ${({ theme }) => theme.color.terracota};
  margin-right: -1px;

  &:first-child {
    border-bottom-left-radius: ${({ theme }) => theme.spacing.xs};
    border-top-left-radius: ${({ theme }) => theme.spacing.xs};
  }

  &:last-child {
    border-bottom-right-radius: ${({ theme }) => theme.spacing.xs};
    border-top-right-radius: ${({ theme }) => theme.spacing.xs};
  }

  &:first-child > * {
    border-bottom-left-radius: 6px;
    border-top-left-radius: 6px;
  }

  &:last-child > * {
    border-bottom-right-radius: 6px;
    border-top-right-radius: 6px;
  }
`
const StyledLeftChevronIcon = styled(LeftChevronIcon)`
  fill: ${({ theme }) => theme.color.terracota};
  height: ${({ theme }) => theme.spacing.s};
  width: ${({ theme }) => theme.spacing.s};
`
const StyledRightChevronIcon = styled(StyledLeftChevronIcon)`
  transform: rotate(180deg);
`
