import React from 'react'

import { Paginator } from '@/components/_shared/paginator/Paginator'
import { usePagination } from '@/contexts/pagination'

interface IProps {
  currPageIndex: number
}

export const PaginatorWrapper: React.FC<IProps> = ({ currPageIndex }) => {
  const {
    paginationInfo: { totalCount, lastPage },
  } = usePagination()

  if (!totalCount || lastPage <= 1) {
    return null
  }

  return <Paginator lastPageIndex={lastPage} currPageIndex={currPageIndex} />
}
